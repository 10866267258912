<template>
  <v-img
    src="img/hero.jpg"
    class="white--text mt-n16"
    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    height="50vh"
  >
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="auto" align="center">
          <h1>Ralf de Rijcke</h1>
          <p class="white--text text-lighten-2">
            Blogs about software development and other techy stuff!
          </p>
          <v-btn rounded primary to="/blog" class="white--text" color="blue">
            Start reading
            <v-icon class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
