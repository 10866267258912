<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" v-if="group">
      <v-list nav>
        <v-list-item
          link
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          :aria-label="item.title"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      elevate-on-scroll
      :color="isTransparent ? 'transparent' : 'white'"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndDown"
        :class="{ 'white--text': isTransparent }"
        aria-label="Menu"
      ></v-app-bar-nav-icon>
      <v-list color="transparent">
        <v-list-item two-line link to="/" aria-label="Home">
          <v-list-item-content>
            <v-list-item-title
              class="title"
              :class="{ 'white--text': isTransparent }"
              >Ralf</v-list-item-title
            >
            <v-list-item-subtitle
              :class="{ 'grey--text text--lighten-2': isTransparent }"
              >de Rijcke</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
        <v-btn
          text
          link
          :to="item.to"
          v-for="item in items"
          :key="item.title"
          :class="{ 'white--text': isTransparent }"
          :aria-label="item.title"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </v-app-bar>

    <v-main color="transparent">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        { title: 'Home', to: '/', icon: 'mdi-home-outline' },
        { title: 'Blog', to: '/blog', icon: 'mdi-image' },
        { title: 'About', to: '/about', icon: 'mdi-help-box' }
      ],
      offsetTop: 0
    };
  },
  computed: {
    group: function() {
      return this.drawer && this.$vuetify.breakpoint.smAndDown;
    },
    isTransparent: function() {
      return this.$route.path == '/' && !this.offsetTop;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.offsetTop = window.top.scrollY;
    }
  },
  metaInfo() {
    return {
      title: 'Ralf de Rijcke | ' + this.$route.name,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Ralf de Rijcke | ' + this.$route.name
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ralfderijcke.nl' + this.$route.path
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Hide active background color */
.v-list-item--active::before,
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
