<template>
  <v-card tile elevation="0">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ post.title }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ post.description }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="80" color="white" class="mt-0">
        <v-list-item class="pa-0">
          <v-list-item-content class="pa-0 grey--text text--darken-3">
            <v-list-item-title class="headline font-weight-light">
              {{ moment(post.date).format('Do') }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                moment(post.date)
                  .format('MMM YYYY')
                  .toUpperCase()
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn text color="primary" :to="post.route">READ MORE</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    moment: function(date) {
      return moment(date);
    }
  }
};
</script>
