<template>
  <v-container>
    <h2 align="center" class="ma-4 text-uppercase font-weight-light">
      Recent posts
    </h2>
    <v-row dense>
      <v-col
        v-for="post in posts.slice(0, 4)"
        :key="post.slug"
        cols="12"
        xl="4"
        md="6"
      >
        <post-card :post="post" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PostCard from '../components/PostCard';

export default {
  components: {
    PostCard
  },
  data() {
    return {
      posts: []
    };
  },
  created() {
    this.$http
      .get('/blog/index.json')
      .then(
        response => (this.posts = JSON.parse(JSON.stringify(response.data)))
      );
  }
};
</script>
