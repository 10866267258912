<template>
  <div>
    <Hero />
    <Recent />
  </div>
</template>

<script>
import Hero from '../components/Hero';
import Recent from '../components/Recent';

export default {
  components: {
    Hero,
    Recent
  }
};
</script>
